import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend'; 
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const availableLanguages = ['he', 'ru', 'ar', 'en', 'fr', 'es'];
const version = 'v2.114';

i18n
  .use(Backend) 
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    fallbackLng: 'en', // fallback language is english.

    detection: {
      checkWhitelist: true, // options for language detection
    },
    backend: {
      backends: [
        LocalStorageBackend,  // primary
        HttpApi, // fallback
      ],
      backendOptions: [
        {
          // for LocalStorage
          prefix: 'i18next_res_',
          expirationTime: 60 * 60 * 1000,
          defaultVersion: version,
          store: window.localStorage,
        },
        {
          // for HttpApi
          loadPath: '/locales/{{lng}}/{{ns}}.json?'+version,
        },
      ],
    },
    debug: false,

    whitelist: availableLanguages,
    partialBundledLanguages: true,
    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
  });

export default i18n;